import { QRCodeSVG } from "qrcode.react";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import bankId from "../../../../../../api/rest/auth/BankID";
import { Alert } from "../../../../../../components/design-system/Alert";
import { Button } from "../../../../../../components/design-system/Button";
import BankID from "../../../../../../components/design-system/icons/BankId";
import { Loading } from "../../../../../../components/design-system/Loading";
import type {
  BankIdAuthDetails,
  NewSignatureRequest,
} from "../../../../../../types/models/auth";

type SignWithBankIdProps = {
  onSign: (refId: string) => void;
  onReceiveOrderRef?: (refId: string) => void;
  onFailed?: () => void;
  onLoadingChange?: (isLoading: boolean) => void;
  request: NewSignatureRequest;
};

const SignWithBankId = ({
  onSign,
  onReceiveOrderRef = () => {},
  onFailed = () => {},
  onLoadingChange = () => {},
  request,
}: SignWithBankIdProps) => {
  const i18n = useTranslation();

  const [signResponse, setSignResponse] = useState<BankIdAuthDetails>();
  const [bankIdSignTerminated, setBankIdSignTerminated] = useState(false);
  const [bankIdFailed, setBankIdFailed] = useState(false);
  const [bankIdLocalFailed, setBankIdLocalFailed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === bankId.BANK_ID_RETURN_HASH) {
      location.hash = "";
    }
  }, [location]);

  const signMutation = bankId.useInitSignMutation({
    retry: false,
    onSuccess: (data) => setSignResponse(data),
    onError: () => setBankIdFailed(true),
  });

  const qrCodeMutation = bankId.useQRCodeQuery(
    signResponse?.qrStartToken ?? "",
    {
      retry: false,
      refetchInterval: 3000,
      enabled: !bankIdSignTerminated && !!signResponse?.qrStartToken,
    }
  );
  const collectSignQuery = bankId.useCollectSignQuery(
    signResponse?.orderRef ?? "",
    {
      retry: false,
      refetchInterval: 2000,
      enabled:
        !bankIdSignTerminated &&
        signMutation.isSuccess &&
        !!signResponse?.orderRef,
    }
  );

  useEffect(() => signMutation.mutate(request), []);

  useEffect(() => {
    if (signResponse?.orderRef) {
      onReceiveOrderRef(signResponse?.orderRef);
    }
  }, [signResponse?.orderRef, onReceiveOrderRef]);

  useEffect(() => {
    if (collectSignQuery.isError) {
      setBankIdFailed(true);
      setBankIdSignTerminated(true);
      return;
    }
    if (collectSignQuery.isSuccess) {
      if (["complete", "failed"].includes(collectSignQuery.data.status)) {
        setBankIdSignTerminated(true);
      }

      if (collectSignQuery.data.status === "failed") {
        setBankIdFailed(true);
      }
    }
  }, [
    collectSignQuery.isSuccess,
    collectSignQuery.data,
    collectSignQuery.isError,
  ]);

  useEffect(() => {
    if (collectSignQuery.data?.status === "failed") {
      onFailed();
    }
  }, [onFailed, collectSignQuery.data]);

  useEffect(() => {
    const { status, signature } = collectSignQuery.data ?? {};
    if (status === "complete" && signature) {
      onSign(signature);
    }
  }, [onSign, collectSignQuery.data]);

  const isLoading = useMemo(() => {
    const waitingUserSignInBankId =
      collectSignQuery.data?.hintCode === "userSign" ||
      collectSignQuery.data?.status === "complete";
    return signMutation.isLoading || waitingUserSignInBankId;
  }, [
    signMutation.isLoading,
    collectSignQuery.data?.hintCode,
    collectSignQuery.data?.status,
  ]);

  useEffect(() => {
    onLoadingChange(isLoading);
  }, [isLoading, onLoadingChange]);

  function retry() {
    setBankIdSignTerminated(false);
    setBankIdFailed(false);
    signMutation.mutate(request);
  }

  function getErrorMessage() {
    if (signMutation.isError) {
      return signMutation.error.errors
        ? signMutation.error.errors[0].message.code
        : "error.general";
    }
    if (qrCodeMutation.isError) {
      return qrCodeMutation.error.errors
        ? qrCodeMutation.error.errors[0].message.code
        : "error.general";
    }
    if (collectSignQuery.isError) {
      return collectSignQuery.error.errors
        ? collectSignQuery.error.errors[0].message.code
        : "error.general";
    }
    return "error.general";
  }

  function openBankId() {
    if (!signResponse?.autoStartToken) {
      return;
    }

    const bankIdUri = bankId.getOpenBankIdUri(signResponse?.autoStartToken);
    bankId.openBankIdUri(bankIdUri, () => setBankIdLocalFailed(true));
  }

  const isError =
    signMutation.isError || qrCodeMutation.isError || bankIdFailed;

  return (
    <>
      <h3 className="tw-text-center">
        {i18n.t("approval-policy.signWithBankId.title")}
      </h3>
      <p className="tw-my-2 tw-text-center">
        {i18n.t("approval-policy.signWithBankId.text")}
      </p>
      {isLoading && <Loading />}

      {!isLoading && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          {bankIdLocalFailed && (
            <Alert
              type="error"
              className="tw-my-4 tw-max-w-lg tw-rounded tw-border tw-border-error tw-p-2"
            >
              <Trans
                components={{
                  helpLink: (
                    <Link
                      target="_blank"
                      to="https://install.bankid.com/"
                      className="tw-text-blue-500 tw-underline"
                    />
                  ),
                }}
                i18nKey="auth.bankId.linkFailed"
              />
            </Alert>
          )}
          {isError && (
            <>
              <Alert className="tw-my-2" type="error">
                {i18n.t(getErrorMessage())}
              </Alert>
              <Button
                className="tw-my-2"
                onClick={() => retry()}
                isLoading={signMutation.isLoading}
              >
                {i18n.t("label.retry")}
              </Button>
            </>
          )}
          {!isError && signMutation.isSuccess && qrCodeMutation.isSuccess && (
            <>
              <QRCodeSVG
                fgColor="currentColor"
                size={256}
                value={qrCodeMutation.data.qrCode}
                className="tw-my-2"
                role="img"
                aria-label={i18n.t("label.qrCode")}
              />
              <Button
                onClick={() => openBankId()}
                prefix={<BankID />}
                className="tw-my-2"
              >
                {i18n.t(
                  "approval-policy.signWithBankId.label.openBankIdOnSameDevice"
                )}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export { SignWithBankId };
