import {
  ArrowLineUp,
  CalendarBlank,
  CalendarCheck,
  CaretRight,
  ChartLineUp,
  ChartPieSlice,
  HandCoins,
  HandDeposit,
  Play,
  ReadCvLogo,
  Signature,
} from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  useShareblocksQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useOptionsProgramQuery } from "../../../api/blockchain/options";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useDeleteOptionsProgram } from "../../../api/rest/options";
import { Button } from "../../../components/design-system/Button";
import { Description } from "../../../components/design-system/Description";
import { Dialog } from "../../../components/design-system/Dialog";
import { EntityItem } from "../../../components/design-system/EntityItem";
import { FormErrorList } from "../../../components/design-system/FormGroup";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  TrashIcon,
} from "../../../components/design-system/icons";
import { Loading } from "../../../components/design-system/Loading";
import { notify } from "../../../components/design-system/Notifications";
import { IconDescription } from "../../../components/IconDescription";
import { PageWrapper } from "../../../components/PageWrapper";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { i18n } from "../../../i18n";
import { APP_ROUTE } from "../../../routes/constants";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "../../../utils/format";
import {
  countTotalShares,
  countTotalVotes,
  getActiveBlocks,
} from "../../../utils/shares";
import { monthsBetween } from "../Options.utils";
import { OptionsForm } from "../OptionsForm";

type SortBy = "participant" | "options" | "role";
type SortOrder = "asc" | "desc";

const OptionsDetailPage = () => {
  const currentCompany = useCurrentCompany();
  const navigate = useNavigate();
  const { optionsId = "" } = useParams<{ optionsId?: string }>();
  const optionsProgramQuery = useOptionsProgramQuery(currentCompany?.orgNumber);
  const optionsPrograms = optionsProgramQuery.data || [];
  const optionsProgram = optionsPrograms.find(
    (program) => program.id === optionsId
  );
  const shareTypesQuery = useShareTypesQuery(currentCompany?.orgNumber, "");
  const shareTypes = shareTypesQuery.data || [];
  const shareTypesMap = Object.fromEntries(shareTypes.map((e) => [e.name, e]));
  const shareType = shareTypes.find(
    (type) => type.name === optionsProgram?.shareType
  );
  const shareBlocksQuery = useShareblocksQuery(currentCompany?.orgNumber, "");
  const shareBlocks = shareBlocksQuery.data || [];
  const activeBlocks = getActiveBlocks(shareBlocks);
  const totalShares = countTotalShares(activeBlocks);
  const totalVotes = countTotalVotes(activeBlocks, shareTypesMap);
  const { t } = useTranslation();
  const { user } = useSession();
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [sortBy, setSortBy] = useState<SortBy>("participant");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");

  const deleteProgramMutation = useDeleteOptionsProgram(
    currentCompany?.orgNumber,
    optionsProgram,
    {
      onSuccess: () => {
        navigate(`${APP_ROUTE.COMPANIES}/${currentCompany?.orgNumber}/options`);
        notify(
          <Description
            title={`${i18n.t("options.delete.success.title")}`}
            description={`${i18n.t("options.delete.success.description", {
              name: optionsProgram?.title,
            })}`}
          />,
          { type: "success" }
        );
      },
    }
  );

  if (
    optionsProgramQuery.isLoading ||
    shareTypesQuery.isLoading ||
    entitiesQuery.isLoading ||
    shareBlocksQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !user ||
    !currentCompany ||
    !optionsId ||
    !entitiesData ||
    !optionsProgram ||
    !shareType
  ) {
    return null;
  }

  const { title, participants = [], id, ...optionsData } = optionsProgram;

  const totalAllocatedOptions = participants?.reduce(
    (sum, participant) => sum + participant.amountOfOptions,
    0
  );
  const shareDilution =
    totalAllocatedOptions / (totalAllocatedOptions + totalShares);
  const totalAllocatedVotes = totalAllocatedOptions * shareType.voteValue;
  const voteDilution = totalAllocatedVotes / (totalAllocatedVotes + totalVotes);

  const matchParticipantToEntity = (participantId: string) => {
    return entitiesData.find(
      (entity) => entity.refId === participantId || entity.id === participantId
    );
  };

  const detailsMap: {
    key: string;
    translationKey: string;
    icon: JSX.Element;
    value: number | string;
  }[] = [
    {
      key: "total_amount_of_options",
      translationKey: t("options.create.max"),
      icon: <ArrowLineUp />,
      value: formatNumber(optionsData.totalAmountOfOptions || 0),
    },
    {
      key: "total_allocated_options",
      translationKey: t("options.allocatedOptions"),
      icon: <HandDeposit />,
      value: formatNumber(totalAllocatedOptions || 0),
    },
    {
      key: "start_date",
      translationKey: t("options.create.startDate"),
      icon: <Play />,
      value: optionsData.startDate,
    },
    {
      key: "period_date",
      translationKey: t("options.period"),
      icon: <Signature />,
      value: t("options.period.range", {
        start: optionsData.strikeStartDate,
        end: optionsData.strikeEndDate,
      }),
    },
    {
      key: "vesting",
      translationKey: t("options.create.vesting"),
      icon: <CalendarBlank />,
      value: `${i18n.t("options.create.period", {
        total: monthsBetween(
          new Date(optionsData.startDate),
          new Date(optionsData.strikeStartDate)
        ),
      })}`,
    },
    {
      key: "duration",
      translationKey: t("options.create.duration"),
      icon: <CalendarCheck />,
      value: `${i18n.t("options.create.period", {
        total: monthsBetween(
          new Date(optionsData.startDate),
          new Date(optionsData.strikeEndDate)
        ),
      })}`,
    },
    ...(optionsProgram.type !== "EmployeeStockOptions" ||
    (optionsData.optionPrice && optionsData.optionPrice !== 0)
      ? [
          {
            key: "option_price",
            translationKey: t("options.create.optionPrice"),
            icon: <HandCoins />,
            value: formatCurrency(
              optionsData.optionPrice || 0,
              optionsData.currency || "SEK"
            ),
          },
        ]
      : []),
    {
      key: "strike_price",
      translationKey: t("options.create.sharePrice"),
      icon: <ChartLineUp />,
      value: formatCurrency(
        optionsData.strikePrice || 0,
        optionsData.currency || "SEK"
      ),
    },
    {
      key: "share_class",
      translationKey: t("label.shareClass"),
      icon: <ReadCvLogo />,
      value: optionsData.shareType,
    },
    {
      key: "share_dilution",
      translationKey: t("label.shareDilution"),
      icon: <ChartPieSlice />,
      value: formatPercentage(shareDilution),
    },
    {
      key: "vote_dilution",
      translationKey: t("label.voteDilution"),
      icon: <ChartPieSlice />,
      value: formatPercentage(voteDilution),
    },
  ];

  const handleSort = (key: SortBy) => {
    if (sortBy === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  const renderSortIcons = (key: SortBy) => (
    <div className="tw-ml-1 tw-flex tw-flex-col tw-gap-0">
      <ChevronUpIcon
        className={
          sortBy === key && sortOrder === "asc"
            ? "tw-text-neutral-800"
            : "tw-text-neutral-300"
        }
        size={12}
      />
      <ChevronDownIcon
        className={
          sortBy === key && sortOrder === "desc"
            ? "tw-text-neutral-800"
            : "tw-text-neutral-300"
        }
        size={12}
      />
    </div>
  );

  const sortedParticipants = [...participants].sort((a, b) => {
    const entityA = matchParticipantToEntity(a.participant.id);
    const entityB = matchParticipantToEntity(b.participant.id);

    if (sortBy === "participant") {
      const nameA = entityA?.name || "";
      const nameB = entityB?.name || "";
      return sortOrder === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    }
    if (sortBy === "options") {
      return sortOrder === "asc"
        ? a.amountOfOptions - b.amountOfOptions
        : b.amountOfOptions - a.amountOfOptions;
    }
    if (sortBy === "role") {
      if (!a.role || !b.role) {
        return 0;
      }
      if (a.role < b.role) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a.role > b.role) {
        return sortOrder === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  if (showEdit) {
    return (
      <OptionsForm
        currentCompany={currentCompany}
        selectedProgram={optionsProgram}
        close={() => setShowEdit(false)}
      />
    );
  }

  return (
    <PageWrapper className="tw-py-4">
      <header className="tw-relative tw-flex tw-flex-col tw-gap-2 tw-pb-4">
        <div className="tw-flex tw-h-8 tw-items-center tw-gap-2">
          <Link
            to={`${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/options`}
            className="tw-flex tw-h-full tw-items-center"
          >
            <p className="tw-text-sm tw-text-primary">
              {t("options.programs.heading")}
            </p>
          </Link>
          <CaretRight />
          <p className="tw-text-sm tw-text-primary">{title}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-2 md:tw-flex-row md:tw-items-center">
          <Description
            title={title}
            titleSize="xxl"
            titleWeight="medium"
            description={`${i18n.t("options.programs.detail.description")}`}
          />
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-2 md:tw-w-auto md:tw-flex-row">
            <Button
              className="max-md:tw-w-full"
              disabled={!currentCompany?.orgNumber}
              onClick={() => setShowEdit(true)}
            >
              <PencilIcon size={24} className="tw-mr-2" />
              {t("options.edit")}
            </Button>
            <Button
              className="max-md:tw-w-full"
              disabled={!currentCompany?.orgNumber}
              onClick={() => setShowDelete(true)}
            >
              <TrashIcon size={24} className="tw-mr-2" />
              {t("options.delete")}
            </Button>
          </div>
        </div>
      </header>
      <hr className="tw-absolute tw-left-0 tw-h-[1px] tw-w-screen tw-bg-gray-200" />
      <section className="tw-flex max-md:tw-flex-col-reverse">
        <div className="tw-w-full tw-pt-4 lg:tw-w-2/3">
          {participants.length === 0 ? (
            <div className="tw-flex">
              <Description
                title={t("options.programs.detail.noParticipants.title")}
                titleSize="xxl"
                description={t(
                  "options.programs.detail.noParticipants.subtitle"
                )}
                descriptionSize="lg"
              />
            </div>
          ) : (
            <table className="tw-w-full tw-text-sm">
              <thead>
                <tr className="tw-border-b">
                  <th className="tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
                    <button
                      type="button"
                      onClick={() => handleSort("participant")}
                      className="tw-flex tw-items-center"
                    >
                      {t("options.participants")}
                      {renderSortIcons("participant")}
                    </button>
                  </th>
                  <th className="tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
                    <button
                      type="button"
                      onClick={() => handleSort("options")}
                      className="tw-flex tw-items-center"
                    >
                      {t("options.participants.options")}
                      {renderSortIcons("options")}
                    </button>
                  </th>
                  {optionsProgram.type === "EmployeeStockOptions" && (
                    <th className="tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary max-md:tw-hidden">
                      <button
                        type="button"
                        onClick={() => handleSort("role")}
                        className="tw-flex tw-items-center"
                      >
                        {t("label.role")}
                        {renderSortIcons("role")}
                      </button>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedParticipants?.map((participant) => {
                  const entity = matchParticipantToEntity(
                    participant.participant.id
                  );

                  return (
                    <tr
                      key={participant.participant.id}
                      className="tw-h-[72px] tw-border-b"
                    >
                      <td className="tw-py-2" data-testid="participant">
                        {entity && (
                          <EntityItem
                            value={{
                              name: entity.name,
                              refId: entity.refId,
                              countryCode: entity.countryCode,
                              id: "",
                              type: "Private",
                            }}
                            displayIcon={false}
                            hasFlag
                          />
                        )}
                        {optionsProgram.type === "EmployeeStockOptions" && (
                          <span className="tw-hidden tw-text-secondary max-md:tw-block">
                            {`${i18n.t(
                              participant.role === "Employee"
                                ? "options.participants.role.employee"
                                : "options.participants.role.boardMember"
                            )}`}
                          </span>
                        )}
                      </td>
                      <td
                        className="tw-px-4 tw-py-2 tw-font-medium"
                        data-testid="participant-options"
                      >
                        {formatNumber(participant.amountOfOptions)}
                      </td>
                      {optionsProgram.type === "EmployeeStockOptions" && (
                        <td className="tw-px-4 tw-py-2 max-md:tw-hidden">
                          {`${i18n.t(
                            participant.role === "Employee"
                              ? "options.participants.role.employee"
                              : "options.participants.role.boardMember"
                          )}`}
                        </td>
                      )}
                    </tr>
                  );
                })}
                <tr className="tw-h-[72px]">
                  <td />
                  <td className="tw-px-4 tw-py-2">
                    <Description
                      title={`${i18n.t("label.total")}`}
                      description={formatNumber(totalAllocatedOptions || 0)}
                      theme="grayBlack"
                      descriptionWeight="medium"
                    />
                  </td>
                  {optionsProgram.type === "EmployeeStockOptions" && <td />}
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <hr className="tw-mx-8 tw-h-screen tw-w-[1px] tw-bg-gray-200 max-md:tw-hidden" />
        <div className="tw-flex tw-w-full tw-max-w-[300px] tw-flex-col tw-gap-4 tw-pb-4 tw-pt-8 lg:tw-w-1/3">
          {detailsMap.map(({ key, translationKey, icon, value }) => (
            <IconDescription
              key={key}
              icon={icon}
              title={translationKey}
              description={String(value)}
              theme="grayBlack"
            />
          ))}
        </div>
      </section>
      {showDelete && (
        <Dialog
          isOpen
          title={t("options.delete")}
          onClose={() => setShowDelete(false)}
          isLoading={deleteProgramMutation.isLoading}
          actions={
            <>
              <Button
                type="submit"
                variant="solid"
                color="danger"
                isLoading={deleteProgramMutation.isLoading}
                onClick={() => {
                  deleteProgramMutation.mutate();
                }}
              >
                {t("options.delete")}
              </Button>
            </>
          }
        >
          <>
            <div>{t("options.delete.description")}</div>
            {deleteProgramMutation.error && (
              <FormErrorList error={deleteProgramMutation.error} />
            )}
          </>
        </Dialog>
      )}
    </PageWrapper>
  );
};

export default OptionsDetailPage;
