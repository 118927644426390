import { useQueryClient } from "@tanstack/react-query";
import { encryption, KeyPair } from "postchain-client";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useRenewSessionQuery } from "../api/blockchain/auth";
import type { MockTruidAuthRequest } from "../api/rest/auth/authTest";
import authTest from "../api/rest/auth/authTest";
import bankId from "../api/rest/auth/BankID";
import Truid from "../api/rest/auth/Truid";
import { Loading } from "../components/design-system/Loading";
import { notify } from "../components/design-system/Notifications";
import { APP_ROUTE } from "../routes/constants";
import type { LoggedInUser, TruidAuthDetails } from "../types/models/auth";
import { LoggedInUserSchema } from "../types/models/auth";
import getIsIOS from "../utils/getIsIOS";
import { Hotjar } from "../utils/hotjar";
import * as monitoring from "../utils/monitoring";

type KeyPairStorage = { publicKey: string; privateKey: string };

type LoginProviders = "BankId" | "Truid";

const userStorageKey = "user";

const setLoginTypeInStorage = (loginType: LoginProviders) => {
  window.localStorage.setItem("loginType", loginType);
};
// # Remove once https://github.com/capchapdev/capchap-frontend/pull/1757 is completed!
const getLoginTypeInStorage = () => {
  return window.localStorage.getItem("loginType");
};
// #

const removeLoginTypeInStorage = () => {
  window.localStorage.removeItem("loginType");
};

const getUserFromStorage = () => {
  const string = window.localStorage.getItem(userStorageKey);

  return string ? LoggedInUserSchema.parse(JSON.parse(string)) : undefined;
};

const setUserInStorage = (user: LoggedInUser) => {
  window.localStorage.setItem(userStorageKey, JSON.stringify(user));
};
const removeUserFromStorage = () => {
  window.localStorage.removeItem(userStorageKey);
};

const getKeyPairFromStorage = (
  key: "keyPair" | "pendingKeyPair"
): KeyPair | undefined => {
  const string = window.localStorage.getItem(key);
  if (!string) {
    return undefined;
  }
  const object = JSON.parse(string) as KeyPairStorage;

  return {
    pubKey: Buffer.from(object.publicKey, "hex"),
    privKey: Buffer.from(object.privateKey, "hex"),
  };
};

const setKeyPairInStorage = (
  key: "keyPair" | "pendingKeyPair",
  keypair: KeyPair
) => {
  window.localStorage.setItem(
    key,
    JSON.stringify({
      publicKey: keypair.pubKey.toString("hex"),
      privateKey: keypair.privKey.toString("hex"),
    })
  );
};

const removeKeyPairFromStorage = (key: "keyPair" | "pendingKeyPair") => {
  window.localStorage.removeItem(key);
};

const orderRefStorageKey = "orderRef";

const setOrderRefInStorage = (orderRef: string) => {
  window.localStorage.setItem(orderRefStorageKey, orderRef);
};

const getOrderRefFromStorage = () =>
  window.localStorage.getItem(orderRefStorageKey);

const removeOrderRefFromStorage = () => {
  window.localStorage.removeItem(orderRefStorageKey);
};

const getKeyPair = () => getKeyPairFromStorage("keyPair");

type Session = {
  TruidLoginState?: TruidAuthDetails;
  user?: LoggedInUser;
  keyPair?: KeyPair;
  loginType?: LoginProviders;
  bankIdLoginFailed?: boolean;
  onInitSameDeviceBankIdSignIn: () => void;
  onCancelSameDeviceBankId: () => void;
  onInitMockBankIdSignIn: () => void;
  onCancelMockBankId: () => void;
  onInitMockTruidSignIn: (request: MockTruidAuthRequest) => void;
  onInitQRBankIdSignIn: () => void;
  onInitTruidSignIn: () => void;
  onCancelQRBankIdAuth: () => void;
  onSignout: () => void;
  onCollectBankIdAuth: (ref: string) => void;
  onCollectTruidAuth: (code: string, state: string) => void;
  BankIdQRCodeQuery?: ReturnType<typeof bankId.useQRCodeQuery>;
  collectBankIdAuthMutation?: ReturnType<typeof bankId.useCollectAuthMutation>;
  initSameDeviceBankIdAuthMutation?: ReturnType<
    typeof bankId.useInitAuthMutation
  >;
  initMockBankIdAuthMutation?: ReturnType<
    typeof authTest.useInitMockBankIdMutation
  >;
  initMockTruidAuthMutation?: ReturnType<
    typeof authTest.useInitMockTruidMutation
  >;
  initBankIdQRAuthMutation?: ReturnType<typeof bankId.useInitAuthMutation>;
  initTruidAuthMutation?: ReturnType<typeof Truid.useInitAuthMutation>;
  collectTruidAuthMutation?: ReturnType<typeof Truid.useCollectAuthMutation>;
};
const SessionContext = createContext<Session>({
  onInitSameDeviceBankIdSignIn: () => "",
  onCancelSameDeviceBankId: () => "",
  onInitMockBankIdSignIn: () => "",
  onCancelMockBankId: () => "",
  onInitMockTruidSignIn: (_: MockTruidAuthRequest) => "",
  onInitQRBankIdSignIn: () => "",
  onCancelQRBankIdAuth: () => "",
  onSignout: () => "",
  onCollectBankIdAuth: () => "",
  onInitTruidSignIn: () => "",
  onCollectTruidAuth: () => "",
});

const useSession = () => {
  const session = useContext(SessionContext);

  return session;
};

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [orderRef, setOrderRef] = useState<string | null>();
  const [user, setUser] = useState<LoggedInUser>();
  const [keyPair, setKeyPair] = useState<KeyPair | undefined>(getKeyPair());
  const [loading, setLoading] = useState(false);
  const [qrStartToken, setQRStartToken] = useState("");
  const [TruidLoginState, setTruidLoginState] = useState<TruidAuthDetails>();
  const [loginType, setLoginType] = useState<LoginProviders>();
  const [bankIdLoginFailed, setBankIdLoginFailed] = useState(false);
  const isCanceledRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  monitoring.setUser(user);
  Hotjar.identify(user);

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect_url");
    navigate(
      redirectUrl &&
        Object.values(APP_ROUTE).some((item) => redirectUrl.startsWith(item))
        ? redirectUrl
        : APP_ROUTE.HOME
    );
  };

  const handleSignedIn = (
    data: { user: LoggedInUser },
    type: LoginProviders
  ) => {
    queryClient.invalidateQueries();
    const loggedInUser = data.user;
    const pendingKeyPairFromStorage = getKeyPairFromStorage("pendingKeyPair");
    if (!loggedInUser || !pendingKeyPairFromStorage) {
      handleSignout();
      return;
    }
    removeKeyPairFromStorage("pendingKeyPair");
    setKeyPairInStorage("keyPair", pendingKeyPairFromStorage);
    setKeyPair(pendingKeyPairFromStorage);
    setUserInStorage(loggedInUser);
    setUser(loggedInUser);
    setLoginType(type);
    setLoginTypeInStorage(type);

    if (location.pathname === APP_ROUTE.SIGN_IN) {
      handleRedirect();
    }

    handleReset();

    monitoring.addBreadcrumb({
      category: "session",
      message: "user signed in",
      level: "info",
    });
  };

  const handleSignout = () => {
    removeUserFromStorage();
    removeKeyPairFromStorage("keyPair");
    removeLoginTypeInStorage();
    setKeyPair(undefined);
    setUser(undefined);
    setOrderRef(null);
    setTruidLoginState(undefined);
    setLoginType(undefined);

    monitoring.addBreadcrumb({
      category: "session",
      message: "user signed out",
      level: "info",
    });
  };
  const handleResetBankIdQRCode = () => {
    BankIdQRCodeQuery.remove();
    setQRStartToken("");
  };

  const handleReset = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setOrderRef(null);
    removeOrderRefFromStorage();
    initBankIdQRAuthMutation.reset();
    initTruidAuthMutation.reset();
    collectBankIdAuthMutation.reset();
    collectTruidAuthMutation.reset();
    handleResetBankIdQRCode();
    setTruidLoginState(undefined);
  };

  const waitAndCollectBankIDResult = (
    newOrderRef: string | undefined | null,
    timeout = 2000
  ) => {
    if (newOrderRef && !isCanceledRef.current) {
      // Retry mechanism if useCollectAuthMutation onSuccess status is pending
      timeoutRef.current = setTimeout(
        () => collectBankIdAuthMutation.mutate(newOrderRef),
        timeout
      );
    }
  };

  const collectBankIdAuthMutation = bankId.useCollectAuthMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "complete") {
        handleSignedIn({ user: data.user }, "BankId");
      } else if (status === "failed") {
        // todo: this would rather be error response
        handleSignout();
      } else if (status === "pending" && orderRef) {
        waitAndCollectBankIDResult(orderRef);
      }
    },
    onError: (error, variables, context) => {
      console.error(
        "collectBankIdAuthMutation onError error:",
        error,
        "variables",
        variables,
        "context",
        context
      );
      setOrderRef(null);
      removeOrderRefFromStorage();
      removeKeyPairFromStorage("pendingKeyPair");
    },
  });

  const collectTruidAuthMutation = Truid.useCollectAuthMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "success") {
        handleSignedIn({ user: data.user }, "Truid");
      } else {
        handleSignout();
      }
    },
    onError: () => setTruidLoginState(undefined),
  });
  const handleCollectTruidAuth = (code: string, state: string) => {
    const pubKey =
      getKeyPairFromStorage("pendingKeyPair")?.pubKey.toString("hex") ?? "";
    return collectTruidAuthMutation.mutate({
      code,
      state,
      pubKey,
    });
  };

  const BankIdQRCodeQuery = bankId.useQRCodeQuery(qrStartToken, {
    refetchInterval: 3000,
    enabled: !!qrStartToken,
  });
  const cancelBankIdAuthMutation = bankId.useCancelAuthMutation();

  const initBankIdQRAuthMutation = bankId.useInitAuthMutation({
    onSuccess: (data) => {
      setQRStartToken(data.qrStartToken || "");
      setOrderRef(data.orderRef);
      waitAndCollectBankIDResult(data.orderRef);
    },
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const initTruidAuthMutation = Truid.useInitAuthMutation({
    onSuccess: (data) => {
      setTruidLoginState(data);
    },
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const handleCancelQRBankIdAuth = () => {
    isCanceledRef.current = true;
    if (orderRef) {
      cancelBankIdAuthMutation.mutate(orderRef);
    }
    handleReset();
  };

  const handleInitQRBankIdSignIn = () => {
    isCanceledRef.current = false;
    collectBankIdAuthMutation.reset();
    handleResetBankIdQRCode();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initBankIdQRAuthMutation.mutate(newKeyPair.pubKey.toString("hex"));
  };

  const handleInitTruidSignIn = () => {
    collectTruidAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initTruidAuthMutation.mutate(newKeyPair.pubKey.toString("hex"));
  };

  const i18n = useTranslation();

  const initSameDeviceBankIdAuthMutation = bankId.useInitAuthMutation({
    onSuccess: (data) => {
      const { autoStartToken } = data;
      if (!autoStartToken) {
        return;
      }
      const bankIdUri = bankId.getOpenBankIdUri(autoStartToken);

      setOrderRefInStorage(data.orderRef);
      setOrderRef(data.orderRef);
      if (!getIsIOS()) {
        // On non iOS devide such as laptops and Android it's safe to poll for result immediately
        waitAndCollectBankIDResult(data.orderRef, 1000);
      }

      bankId.openBankIdUri(bankIdUri, () => setBankIdLoginFailed(true));
    },
    onError: () => {
      // todo: show real error
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const handleInitSameDeviceBankIdSignIn = () => {
    setBankIdLoginFailed(false);
    isCanceledRef.current = false;
    collectBankIdAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initSameDeviceBankIdAuthMutation.mutate(newKeyPair.pubKey.toString("hex"));
  };

  const handleCancelSameDeviceBankIdSignIn = () => {
    isCanceledRef.current = true;
    if (orderRef) {
      cancelBankIdAuthMutation.mutate(orderRef);
    }
    handleReset();
  };

  const initMockBankIdAuthMutation = authTest.useInitMockBankIdMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "complete") {
        handleSignedIn({ user: data.user }, "BankId");
      } else if (status === "failed") {
        handleSignout();
      }
    },
    onError: () => {
      // todo: show real error
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const initMockTruidAuthMutation = authTest.useInitMockTruidMutation({
    onSuccess: (data) => {
      const { status } = data;
      if (status === "success") {
        handleSignedIn({ user: data.user }, "Truid");
      } else {
        handleSignout();
      }
    },
    onError: () => {
      // todo: show real error
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const handleInitMockBankIdSignIn = () => {
    const promptRefId =
      window.prompt(
        "Personnummer",
        window.localStorage.getItem("mockRefId") || ""
      ) ?? "";

    const refId = promptRefId || window.localStorage.getItem("mockRefId")!;
    window.localStorage.setItem("mockRefId", refId);

    if (refId.trim() === "") {
      return;
    }

    collectBankIdAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initMockBankIdAuthMutation.mutate({
      refId,
      pubKey: newKeyPair.pubKey.toString("hex"),
      countryCode: "SE",
    });
  };

  const handleInitMockTruidSignIn = (request: MockTruidAuthRequest) => {
    const cachedData = JSON.parse(
      window.localStorage.getItem("mockTruidData") ?? "{}"
    );

    const passportNumber = request?.passportNumber ?? cachedData.passportNumber;
    const TruidUserId = request?.TruidUserId ?? cachedData.TruidUserId;
    const countryCode = request?.countryCode ?? cachedData.countryCode;

    window.localStorage.setItem(
      "mockTruidData",
      JSON.stringify({
        passportNumber,
        TruidUserId,
        countryCode,
      })
    );
    collectTruidAuthMutation.reset();
    initMockTruidAuthMutation.reset();
    const newKeyPair = encryption.makeKeyPair();
    setKeyPairInStorage("pendingKeyPair", newKeyPair);
    initMockTruidAuthMutation.mutate({
      passportNumber,
      TruidUserId,
      countryCode,
      pubKey: newKeyPair.pubKey.toString("hex"),
    });
  };

  const handleCancelMockBankIdSignIn = () => {
    isCanceledRef.current = true;
    if (orderRef) {
      cancelBankIdAuthMutation.mutate(orderRef);
    }
    handleReset();
  };

  const renewSessionQuery = useRenewSessionQuery({
    enabled: false,
    onSuccess: (data) => {
      setLoading(false);
      if (!data) {
        const orderRefInStorage = getOrderRefFromStorage();

        // existing orderRef means auth is in progress hence we should not clear it
        if (!orderRef && !orderRefInStorage) {
          handleSignout();
        }

        return;
      }

      setKeyPairInStorage("keyPair", data.keyPair);
      setKeyPair(data.keyPair);
      setUserInStorage(data.user);
      setUser(data.user);
      if (location.pathname === APP_ROUTE.SIGN_IN) {
        handleRedirect();
      }
    },
  });

  useEffect(() => {
    let orderRefInStorage = getOrderRefFromStorage();

    if (orderRefInStorage && !orderRef) {
      setOrderRef(orderRefInStorage);
      waitAndCollectBankIDResult(orderRefInStorage, 0);
    }

    const callback = () => {
      // Refetch since Chrome on Android resets the state when the app is in the background somehow
      orderRefInStorage = getOrderRefFromStorage();
      if (user && window.document.visibilityState === "visible") {
        setLoading(true);
        renewSessionQuery.refetch();
      } else if (
        !user &&
        getIsIOS() &&
        (orderRef || orderRefInStorage) &&
        window.document.visibilityState === "visible"
      ) {
        waitAndCollectBankIDResult(orderRef ?? orderRefInStorage, 1000);
      }
    };

    window.document.addEventListener("visibilitychange", callback);

    return () => {
      window.document.removeEventListener("visibilitychange", callback);
    };
  }, []);

  if (loading && !user) {
    return <Loading />;
  }

  return (
    <SessionContext.Provider
      value={{
        onInitSameDeviceBankIdSignIn: handleInitSameDeviceBankIdSignIn,
        onCancelSameDeviceBankId: handleCancelSameDeviceBankIdSignIn,
        onInitMockBankIdSignIn: handleInitMockBankIdSignIn,
        onCancelMockBankId: handleCancelMockBankIdSignIn,
        onInitQRBankIdSignIn: handleInitQRBankIdSignIn,
        onInitTruidSignIn: handleInitTruidSignIn,
        onInitMockTruidSignIn: handleInitMockTruidSignIn,
        onCancelQRBankIdAuth: handleCancelQRBankIdAuth,
        onSignout: handleSignout,
        onCollectBankIdAuth: setOrderRef,
        onCollectTruidAuth: handleCollectTruidAuth,
        BankIdQRCodeQuery,
        collectBankIdAuthMutation,
        initSameDeviceBankIdAuthMutation,
        initMockBankIdAuthMutation,
        initMockTruidAuthMutation,
        initBankIdQRAuthMutation,
        initTruidAuthMutation,
        collectTruidAuthMutation,
        user,
        keyPair,
        TruidLoginState,
        loginType,
        bankIdLoginFailed,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export {
  getKeyPair,
  getLoginTypeInStorage,
  getOrderRefFromStorage,
  getUserFromStorage,
  SessionProvider,
  useSession,
};
